import React, {
  type ReactNode,
  type Dispatch,
  type SetStateAction,
} from "react";
import { RootStore } from "stores";
import { Button, ButtonGroup } from "react-bootstrap";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import { type TicketInstance } from "stores/UserStore/TicketModel";
import { OverviewPage } from "pages";

interface TicketsProps {
  setTicket: Dispatch<SetStateAction<TicketInstance>>;
  setShowDetailModal: Dispatch<SetStateAction<boolean>>;
  setPincode: Dispatch<SetStateAction<string>>;
  setShowDeleteGroupModal: Dispatch<SetStateAction<boolean>>;
}

type DataColumnsGetterProps = {
  dataField: string;
  text: string;
  formatter?: (...args: any[]) => ReactNode;
}[];

const Tickets = ({
  setShowDeleteGroupModal,
  setShowDetailModal,
  setPincode,
  setTicket,
}: TicketsProps) => {
  const { PerformanceStore, RoomStore, User } = RootStore;

  const { IndividualTickets, PartnerTickets, GroupTickets } =
    PerformanceStore.current;

  const defaultStartColumns: DataColumnsGetterProps = [
    {
      dataField: "user",
      text: "Naam",
      formatter: function Formatter(_cell) {
        return !_cell ? <i>Niet geclaimd</i> : _cell;
      },
    },
  ];

  const defaultEndColumns: DataColumnsGetterProps = [
    {
      dataField: "initialRoomId",
      text: "Toegewezen aan kamer",
      formatter: function Formatter(_cell) {
        if (_cell) {
          return RoomStore.rooms
            .slice()
            .find((room: { id: string }) => room.id === _cell).name;
        }

        return <i>Niet gescand</i>;
      },
    },
    {
      dataField: "date",
      text: "Aanmaakdatum",
    },
    {
      dataField: "scannedAt",
      text: "Gescand",
      formatter: function Formatter(
        scannedAt: string,
        row: {
          object: {
            user: {};
            scan: () => void;
          };
        }
      ) {
        return scannedAt ? (
          "Ja"
        ) : !!row.object.user ? (
          <Button
            onClick={() => {
              row.object.scan();
            }}
          >
            Scannen
          </Button>
        ) : (
          <></>
        );
      },
    },
    {
      dataField: "currentProgression",
      text: "Locatie",
      formatter: function Formatter(currentProgression: { roomName: string }) {
        if (!currentProgression) {
          return "Onbekend";
        }

        return currentProgression?.roomName;
      },
    },
  ];

  const individualColumns = defaultStartColumns.concat(defaultEndColumns);

  const partnerColumns = defaultStartColumns
    .concat([
      {
        dataField: "partnerType",
        text: "Relatie",
      },
      {
        dataField: "inviter",
        text: "Uitgenodigd door",
        formatter: function Formatter(_cell, row) {
          return <Link to={`/gebruikers/${row.inviterId}`}>{_cell}</Link>;
        },
      },
    ])
    .concat(defaultEndColumns);

  const groupColumns = defaultStartColumns.concat(defaultEndColumns);

  const onView = (ticket: TicketInstance) => {
    setTicket(ticket.object);
    setShowDetailModal(true);
  };

  return (
    <>
      {IndividualTickets && (
        <div className="row mb-5">
          <div className="col-lg-12">
            <OverviewPage
              name="Bezoekers"
              columns={individualColumns}
              items={IndividualTickets}
              onView={(ticket) => onView(ticket)}
            ></OverviewPage>
          </div>
        </div>
      )}

      {PartnerTickets && (
        <div className="row mb-5">
          <div className="col-lg-12">
            <OverviewPage
              name="Uitnodigingen"
              columns={partnerColumns}
              items={PartnerTickets}
              onView={(ticket) => onView(ticket)}
            ></OverviewPage>
          </div>
        </div>
      )}

      {GroupTickets &&
        Object.keys(GroupTickets).map((key) => {
          const tickets = GroupTickets[key];
          const available = tickets.filter((ticket) => ticket.user).length;
          const total = tickets.length;
          const inviter = key.substring(0, key.indexOf("_"));
          return (
            <div className="row mb-5">
              <div className="col-lg-12">
                <OverviewPage
                  name={`${inviter} (${available} / ${total} geclaimd)`}
                  columns={groupColumns}
                  items={tickets}
                  onView={(ticket) => onView(ticket)}
                ></OverviewPage>

                {User.IsAdmin && (
                  <ButtonGroup className="mt-3">
                    <Button
                      variant="danger"
                      onClick={() => {
                        setPincode(tickets[0].object.pincode);
                        setShowDeleteGroupModal(true);
                      }}
                    >
                      Groep verwijderen
                    </Button>
                  </ButtonGroup>
                )}
              </div>
            </div>
          );
        })}
    </>
  );
};

export default observer(Tickets);
