import React, { useCallback, useState, useEffect } from "react";
import { OverviewPage } from "pages";
import { RootStore } from "stores";
import { RequestFiltersSnapshot } from "stores/Models/RequestFilters";
import { PerformanceForm } from "components/forms";
import { PerformanceSnapshot } from "stores/PerformanceStore/PerformanceModel";
import moment from "moment";

const PerformanceOverview = () => {
  const [performances, setPerformances] = useState<any[]>([]);
  const [filters, setFilters] = useState<RequestFiltersSnapshot | null>();
  const [showModal, setShowModal] = useState<boolean>();

  const fetchTableData = useCallback(async () => {
    await RootStore.PerformanceStore.load();

    setPerformances(RootStore.PerformanceStore.performances);
    setFilters(RootStore.PerformanceStore.filters);
  }, []);

  useEffect(() => {
    fetchTableData();
  }, [fetchTableData]);

  const columns = [
    {
      dataField: "date",
      text: "Datum",
      formatter: function Formatter(_cell, row) {
        return moment(_cell).format("DD-MM-YYYY");
      },
    },
    {
      dataField: "from",
      text: "Begintijd",
    },
    {
      dataField: "to",
      text: "Eindtijd",
    },
    {
      dataField: "Available",
      text: "Plaatsen over",
    },
  ];

  const sortableFields = [
    {
      label: "Datum",
      value: "date",
      defaultAscending: false,
    },
  ];

  const onCreateSubmit = async (performance: Partial<PerformanceSnapshot>) => {
    await RootStore.PerformanceStore?.create(performance);
    setShowModal(false);
  };

  const form = <PerformanceForm onSubmitCallback={onCreateSubmit} />;

  return (
    <>
      {performances && (
        <OverviewPage
          name="Voorstellingen"
          singular="Voorstelling"
          columns={columns}
          onLoad={fetchTableData}
          items={performances}
          filters={filters}
          search={false}
          sort={true}
          sortableFields={sortableFields}
          path={`locaties/${RootStore.LocationStore.current.id}/voorstellingen`}
          showModal={showModal}
          form={form}
        ></OverviewPage>
      )}
    </>
  );
};

export default PerformanceOverview;
