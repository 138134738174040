export enum INDUSTRY {
  EDUCATION = "Education",
  CONSTRUCTION = "Construction",
  INSTALLATION_SECTOR = "InstallationSector",
  FINANCIAL_SERVICES = "FinancialServices",
  OTHER = "Other",
}

export enum INDUSTRY_NL {
  EDUCATION = "Onderwijs",
  CONSTRUCTION = "Bouw",
  INSTALLATION_SECTOR = "Installatie sector",
  FINANCIAL_SERVICES = "Financiële diensten",
  OTHER = "Ander",
  NOT_CHOSEN = "Niet ingesteld",
}
