import React, { useState, useEffect, useCallback } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { Button, ButtonGroup } from "react-bootstrap";
import { CustomModal, Pagination, Loader, Filterbar } from "components";
import { Link } from "react-router-dom";
import { RootStore } from "stores";
import { observer } from "mobx-react-lite";

const OverviewPage = ({
  name,
  singular,
  path,
  onLoad,
  onView,
  filters,
  search = true,
  sort = true,
  sortableFields,
  columns,
  items,
  form,
  showModal,
  extraActions,
  customFilters,
}: {
  name: string | JSX.Element;
  columns: any[];
  items: any;
  path?: string;
  singular?: string;
  onLoad?: () => Promise<void>;
  onView?: (item) => void;
  sortableFields?: {
    value: string;
    label: string;
    defaultAscending?: boolean;
  }[];
  filters?: any;
  search?: boolean;
  sort?: boolean;
  form?: JSX.Element;
  showModal?: boolean;
  extraActions?: any;
  customFilters?: JSX.Element;
}) => {
  const loadData = useCallback(async () => {
    setLoading(true);

    if (onLoad) await onLoad();

    setLoading(false);
  }, [onLoad]);

  useEffect(() => {
    if (onLoad) loadData();
  }, [onLoad, loadData]);

  useEffect(() => {
    if (showModal !== undefined) setCreateModalVisible(showModal);
  }, [showModal]);

  const [loading, setLoading] = useState(false);
  const [createModalVisible, setCreateModalVisible] = useState(false);

  return (
    <div>
      <div className="page-header">
        <h3 className="page-title position-relative">
          {name}
          <Loader position="right" display={loading} />
        </h3>
        {form && RootStore.User.IsAdmin && (
          <>
            <Button onClick={() => setCreateModalVisible(true)}>
              + {singular} toevoegen
            </Button>

            <CustomModal
              visible={createModalVisible}
              onHide={() => setCreateModalVisible(false)}
              modalTitle={`${singular} toevoegen`}
            >
              {form}
            </CustomModal>
          </>
        )}
      </div>

      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Overzicht</h4>

              <div className="row">
                <div className="col-12">
                  {filters && (sort || search) && (
                    <Filterbar
                      filters={filters}
                      onFiltersChanged={loadData}
                      search={search}
                      sort={sort}
                      sortableFields={sortableFields}
                      customFilters={customFilters}
                    ></Filterbar>
                  )}

                  {items && items.length > 0 ? (
                    <ToolkitProvider
                      keyField="id"
                      bootstrap4
                      data={items}
                      columns={
                        path || onView
                          ? columns.concat({
                              dataField: "action",
                              text: "Acties",
                              sort: false,
                              formatter: function Formatter(_cell, row) {
                                return (
                                  <ButtonGroup>
                                    {onView ? (
                                      <Button
                                        onClick={() => onView(row)}
                                        className="btn btn-outline-primary"
                                        variant="outline-primary"
                                      >
                                        <i className="mdi mdi-eye-outline text-primary"></i>
                                        Bekijken
                                      </Button>
                                    ) : (
                                      <Link
                                        className="btn btn-outline-primary"
                                        to={`/${path}/${row.id}`}
                                      >
                                        <i className="mdi mdi-eye-outline text-primary"></i>
                                        Bekijken
                                      </Link>
                                    )}
                                  </ButtonGroup>
                                );
                              },
                            })
                          : columns
                      }
                    >
                      {(props) => (
                        <div>
                          <BootstrapTable
                            {...props.baseProps}
                            wrapperClasses="table-responsive"
                          />
                          {filters ? (
                            <Pagination
                              filters={filters}
                              onPaginationChange={loadData}
                            ></Pagination>
                          ) : (
                            ""
                          )}
                        </div>
                      )}
                    </ToolkitProvider>
                  ) : (
                    "Geen resultaten"
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(OverviewPage);
