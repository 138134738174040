import React, { type FormEvent, useState } from "react";
import { Form, Button } from "react-bootstrap";
import Loader from "components/Loader";
import { PerformanceInstance } from "stores/PerformanceStore/PerformanceModel";
import {
  DateInput,
  TimeInput,
  NumberInput,
  SearchSelect,
  CheckInput,
} from "./FormElements";
import { RootStore } from "stores";
import { observer } from "mobx-react-lite";
import moment from "moment";
import dateUtil from "util/dateUtil";

const PerformanceForm = ({
  onSubmitCallback,
  onRemoveCallback,
  performance,
}: {
  onSubmitCallback: (
    performanceData: Partial<PerformanceInstance>
  ) => Promise<void>;
  onRemoveCallback?: () => void;
  performance?: PerformanceInstance;
}) => {
  const [loading, setLoading] = useState(false);

  const [selectedDate, setSelectedDate] = useState(
    performance?.date ? moment(performance.date).toDate() : new Date()
  );

  const [selectedFrom, setSelectedFrom] = useState(
    dateUtil.getDefaultFrom(performance?.from)
  );
  const [selectedTo, setSelectedTo] = useState(
    dateUtil.getDefaultTo(performance?.to)
  );
  const [formLocationId, setFormLocationId] = useState<any>(
    performance?.locationId
  );

  const onSubmit = async (formEvent: FormEvent<HTMLFormElement>) => {
    setLoading(true);

    formEvent.preventDefault();

    const performanceData: Partial<PerformanceInstance> = {
      date: moment(selectedDate).format("YYYY-MM-DD"),
      from: formEvent.target[3].value,
      to: formEvent.target[4].value,
      maxVisitors: formEvent.target[5].value,
      published: formEvent.target[6].checked,
      locationId: performance ? performance.locationId : formLocationId,
    };

    if (performance) {
      performanceData.id = performance.id;
    }

    await onSubmitCallback(performanceData);

    setLoading(false);
  };

  return (
    <div className="row">
      <div className="col-md-6">
        <Form onSubmit={onSubmit}>
          <fieldset
            disabled={!RootStore.User.IsAdmin || !!performance?.deletedAt}
          >
            <DateInput
              label="Datum"
              id="date"
              required={true}
              onChange={setSelectedDate}
              defaultValue={selectedDate}
            />

            <SearchSelect
              label="Locatie"
              id="location"
              key="location"
              onSearch={async (searchPhrase: string) => {
                RootStore.LocationStore.filters.setSearchPhrase(
                  searchPhrase,
                  true
                );

                await RootStore.LocationStore.load();

                return RootStore.LocationStore.locations.map((location) => ({
                  value: location.id,
                  label: location.name,
                }));
              }}
              required={false}
              disabled={!!performance}
              onChange={(value) => setFormLocationId(Number(value))}
            >
              {performance && (
                <label className="mt-2">{performance?.locationName}</label>
              )}
            </SearchSelect>

            <TimeInput
              label="Begintijd"
              id="from"
              required={true}
              onChange={setSelectedFrom}
              defaultValue={selectedFrom}
            />

            <TimeInput
              label="Eindtijd"
              id="to"
              required={true}
              onChange={setSelectedTo}
              defaultValue={selectedTo}
            />

            <NumberInput
              label="Maximum aantal bezoekers"
              id="maxVisitors"
              required={true}
              min={performance?.tickets.length ?? 0}
              defaultValue={performance?.maxVisitors ?? 0}
            />

            <CheckInput
              id="published"
              label="Zichtbaar in app"
              defaultValue={!!performance?.published}
            ></CheckInput>

            {performance && (
              <Form.Group>
                <label>Beschikbare plekken</label>
                <p>{performance.Available}</p>
              </Form.Group>
            )}
            {(!performance || !performance.deletedAt) && (
              <>
                <Button
                  type="submit"
                  className="btn btn-primary mr-2 position-relative"
                >
                  <Loader position="right" display={loading}></Loader>
                  {performance ? "Opslaan" : "Toevoegen"}
                </Button>

                {performance && (
                  <Button variant="danger" onClick={onRemoveCallback}>
                    Annuleren
                  </Button>
                )}
              </>
            )}
          </fieldset>
        </Form>
      </div>
      {performance && !performance.deletedAt && (
        <div className="col-md-6">
          <ul>
            <li>{`Gescand: ${performance.Scanned} / ${performance.ScannableTickets}`}</li>

            <li>
              {`Voorstelling gestart: ${performance.Started} / ${performance.Scanned}
              `}
            </li>

            <li>
              {`Audioflow beëindigd: ${performance.Ended} / ${performance.Started}
              `}
            </li>

            <li>
              {`Hulpvragen ingediend: ${performance.SubmittedHelpquestions} / ${performance.Ended}
              `}
            </li>

            {performance?.personasCount.map(({ persona, count }) => {
              return (
                <li key={persona.id}>
                  {persona.name}: {count}
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

export default observer(PerformanceForm);
