import React from "react";
import { Form, Button } from "react-bootstrap";
import { UserInstance } from "stores/UserStore/UserModel";
import { TextInput } from "./FormElements";
import storageUtil from "util/storageUtil";
import dateUtil from "util/dateUtil";

const UserForm = ({
  user,
  onRemoveClick,
}: {
  user?: UserInstance;
  onRemoveClick?: () => void;
}) => {
  return (
    <div className="row">
      <div className="col-md-6">
        <Form>
          {user?.image ? (
            <img
              className="img-xxl mb-4 rounded-circle"
              alt="User"
              src={storageUtil.getStoragePath(user.image)}
            />
          ) : (
            ""
          )}

          <TextInput
            id="firstName"
            placeholder="Voornaam"
            label="Voornaam"
            defaultValue={user?.firstName}
            disabled={true}
          ></TextInput>

          <TextInput
            id="lastName"
            placeholder="Achternaam"
            label="Achternaam"
            defaultValue={user?.lastName}
            disabled={true}
          ></TextInput>

          <TextInput
            id="email"
            placeholder="E-mailadres"
            label="E-mailadres"
            defaultValue={user?.email}
            disabled={true}
          ></TextInput>

          <TextInput
            id="role"
            placeholder="Rol"
            label="Rol"
            defaultValue={user?.Role}
            disabled={true}
          ></TextInput>

          <TextInput
            id="currentIndustry"
            placeholder="Werksector"
            label="Werksector"
            defaultValue={user?.CurrentIndustry}
            disabled={true}
          ></TextInput>

          <TextInput
            id="dateOfBirth"
            placeholder="Geboortedatum"
            label="Geboortedatum"
            defaultValue={user?.DateOfBirth}
            disabled={true}
          ></TextInput>

          <TextInput
            id="phoneNumber"
            placeholder="Telefoonnummer"
            label="Telefoonnummer"
            defaultValue={user?.phoneNumber}
            disabled={true}
          ></TextInput>

          <TextInput
            id="company"
            placeholder="Bedrijf"
            label="Bedrijf"
            defaultValue={user?.company}
            disabled={true}
            className="mb-0"
          ></TextInput>

          {user && !!!user.deletedAt && onRemoveClick && (
            <Button variant="warning" onClick={onRemoveClick} className="mt-3">
              Archiveren
            </Button>
          )}
        </Form>
      </div>
      {user && user.logs && (
        <div className="col-md-6" style={{ position: "relative" }}>
          <div
            style={{
              overflowY: "scroll",
              padding: "10px",
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              backgroundColor: "#012456",
            }}
          >
            {user.logs.length > 0 ? (
              user.logs.map((log: { message: string; createdAt: string }) => {
                return (
                  <p
                    key={`${log.message}${log.createdAt}`}
                    style={{ color: "white" }}
                  >
                    <strong>
                      [{dateUtil.UtcStringToFormattedString(log.createdAt)}]
                    </strong>{" "}
                    {log.message}
                  </p>
                );
              })
            ) : (
              <p style={{ color: "white" }}>Geen informatie</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default UserForm;
