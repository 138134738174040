import React, { type Dispatch, type SetStateAction } from "react";
import { RootStore } from "stores";
import { PerformanceService } from "services";
import { Button, ButtonGroup } from "react-bootstrap";
import { saveAs } from "file-saver";
import { observer } from "mobx-react-lite";

interface ButtonsProps {
  setShowCreateModal: Dispatch<SetStateAction<boolean>>;
}

const Buttons = ({ setShowCreateModal }: ButtonsProps) => {
  const { PerformanceStore, User } = RootStore;

  const exportAttendees = async () => {
    const _export = await PerformanceService.export(
      PerformanceStore.current?.id
    );

    if (_export)
      saveAs(
        new Blob([_export], { type: "text/csv" }),
        `Bezoekerslijst HvS ${PerformanceStore.current?.Date}.csv`
      );
  };

  const exportScannedAttendees = async () => {
    const _export = await PerformanceService.exportScannedAttendees(
      PerformanceStore.current?.id
    );

    if (_export)
      saveAs(
        new Blob([_export], { type: "text/csv" }),
        `Bezoekerslijst (gescande tickets) HvS ${PerformanceStore.current?.Date}.csv`
      );
  };

  const exportHelpQuestions = async () => {
    const _export = await PerformanceService.exportHelpQuestions(
      PerformanceStore.current?.id
    );

    if (_export)
      saveAs(
        new Blob([_export], { type: "text/csv" }),
        `Hulpvragen HvS ${PerformanceStore.current?.Date}.csv`
      );
  };

  return (
    <ButtonGroup className="mb-4">
      {User.IsAdmin && !PerformanceStore.current?.deletedAt && (
        <Button
          onClick={() => setShowCreateModal(true)}
          disabled={PerformanceStore.current?.available <= 0}
        >
          Groep aanmaken
        </Button>
      )}

      <Button onClick={() => exportAttendees()} variant="light">
        Exporteren bezoekerslijst
      </Button>

      <Button onClick={() => exportHelpQuestions()} variant="secondary">
        Exporteren hulpvragen
      </Button>

      <Button onClick={() => exportScannedAttendees()} variant="light">
        Exporteren bezoekers (gescande tickets)
      </Button>
    </ButtonGroup>
  );
};

export default observer(Buttons);
