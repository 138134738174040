import React, { useState } from "react";
import { RootStore } from "stores";
import { TicketForm, TicketGroupForm } from "components/forms";
import CustomModal from "components/CustomModal";
import { TicketInstance } from "stores/UserStore/TicketModel";
import ConfirmModal from "components/ConfirmModal";

import { observer } from "mobx-react-lite";
import Buttons from "./internal/Buttons";
import Tickets from "./internal/Tickets";

const PerformanceAttendanceOverview = () => {
  const { PerformanceStore } = RootStore;

  const [ticket, setTicket] = useState<TicketInstance>();
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showPincodeModal, setShowPincodeModal] = useState(false);
  const [showDeleteGroupModal, setShowDeleteGroupModal] = useState(false);
  const [pincode, setPincode] = useState<string>();

  const onTicketRemove = async (id: number) => {
    await PerformanceStore.current.deleteTicket(id);
    setShowDetailModal(false);
  };

  const onTicketGroupRemove = async () => {
    if (pincode) {
      await PerformanceStore.current.deleteTicketGroup(pincode);
    }

    setShowDeleteGroupModal(false);
  };

  const onCreateGroup = async (groupData: {
    amount: number;
    inviter: string;
    performanceId: number;
  }) => {
    const pincode = await PerformanceStore.current.createGroup(groupData);

    setShowCreateModal(false);
    setPincode(pincode);
    setShowPincodeModal(true);
  };

  return (
    <>
      <Buttons setShowCreateModal={setShowCreateModal} />

      <Tickets
        setShowDeleteGroupModal={setShowDeleteGroupModal}
        setShowDetailModal={setShowDetailModal}
        setPincode={setPincode}
        setTicket={setTicket}
      />

      <CustomModal
        modalTitle="Ticket beheren"
        visible={showDetailModal}
        onHide={() => setShowDetailModal(false)}
      >
        <TicketForm ticket={ticket} onRemoveClick={onTicketRemove}></TicketForm>
      </CustomModal>

      <ConfirmModal
        show={showDeleteGroupModal}
        onHide={() => setShowDeleteGroupModal(false)}
        onConfirm={onTicketGroupRemove}
        modalText="Weet je zeker dat je deze groep wilt verwijderen?"
        modalTitle={"Groep verwijderen"}
      />

      <CustomModal
        modalTitle="Groep aanmaken"
        visible={showCreateModal}
        onHide={() => setShowCreateModal(false)}
      >
        <TicketGroupForm
          performance={PerformanceStore.current}
          onSubmitCallback={onCreateGroup}
        />
      </CustomModal>

      <CustomModal
        modalTitle="Groep aangemaakt"
        visible={showPincodeModal}
        onHide={() => setShowPincodeModal(false)}
      >
        <div className="text-center">
          Deel de volgende pincode met de organisatie/uitnodiger:
          <h4>{pincode}</h4>
        </div>
      </CustomModal>
    </>
  );
};

export default observer(PerformanceAttendanceOverview);
