class exportUtil {
  public exportCsv = (csv: string, title: string) => {
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);

    const today = new Date();
    const date = `${today.getDay()}.${today.getMonth()}.${today.getFullYear()}`;

    link.setAttribute("href", url);
    link.setAttribute("download", `${title}_${date}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
}

export default new exportUtil();
