import moment from "moment-timezone";

class dateUtil {
  public getDateFromTimespan = (timespan: string): Date => {
    const duration = moment.duration(timespan);
    const date = moment();
    date.hours(duration.hours());
    date.minutes(duration.minutes());
    return date.toDate();
  };

  public UtcStringToFormattedString = (dateString: string): string => {
    const date = moment.utc(dateString).tz("Europe/Amsterdam");
    return date.format("DD-MM-yyyy HH:mm");
  };

  public getDefaultFrom = (fromTime?: string): Date => {
    if (fromTime) return this.getDateFromTimespan(fromTime);
    const date = new Date();
    date.setMinutes(date.getMinutes() > 30 ? 30 : 0);
    return date;
  };

  public getDefaultTo = (toTime?: string): Date => {
    if (toTime) return this.getDateFromTimespan(toTime);
    const date = moment(this.getDefaultFrom()).add("30", "m").toDate();
    return date;
  };
}

export default new dateUtil();
