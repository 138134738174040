import { types, flow, type Instance } from "mobx-state-tree";
import { UserService } from "services";

const IndustryStore = types
  .model("IndustryStore", {
    Construction: types.maybe(types.number),
    Education: types.maybe(types.number),
    FinancialServices: types.maybe(types.number),
    InstallationSector: types.maybe(types.number),
    Other: types.maybe(types.number),
  })
  .actions((self) => ({
    load: flow(function* () {
      const response = yield UserService.getIndustries();

      const {
        Construction,
        Education,
        FinancialServices,
        InstallationSector,
        Other,
      } = response.industries;

      self.Construction = Construction ?? 0;
      self.Education = Education ?? 0;
      self.FinancialServices = FinancialServices ?? 0;
      self.InstallationSector = InstallationSector ?? 0;
      self.Other = Other ?? 0;
    }),

    reset: () => {
      self.Construction = 0;
      self.Education = 0;
      self.FinancialServices = 0;
      self.InstallationSector = 0;
      self.Other = 0;
    },
  }));

export default IndustryStore;
export interface IndustryStoreInstance extends Instance<typeof IndustryStore> {}
