import CrudService from "./CrudService";
import { EventSnapshot } from "stores/EventStore/EventModel";
import ApiService from "./ApiService";

class ExportService extends CrudService<EventSnapshot> {
  usersExcel = async (): Promise<any> => {
    const response = await ApiService.get(`${this.basename}/users`);

    return response.data;
  };
}

export default new ExportService("/exports");
