import React, { useState } from "react";
import { RootStore } from "stores";
import { observer } from "mobx-react-lite";
import { OverviewPage } from "pages/Base";
import { Button } from "react-bootstrap";
import { CheckInput } from "components/forms/FormElements";
import ExportService from "services/ExportService";
import exportUtil from "util/exportUtil";
import { INDUSTRY_NL } from "constants/INDUSTRY";

const UsersOverviewPage = () => {
  const { IndustryStore, UserStore } = RootStore;

  const columns = [
    {
      dataField: "fullName",
      text: "Naam",
    },
    {
      dataField: "email",
      text: "E-mail",
    },
    {
      dataField: "Role",
      text: "Rol",
    },
    {
      dataField: "CurrentIndustry",
      text: "Werksector",
    },
    {
      dataField: "activatedAt",
      text: "Geactiveerd",
      formatter: function Formatter(_cell, row) {
        return _cell ? (
          "Ja"
        ) : (
          <Button
            onClick={() => {
              row.activate();
            }}
          >
            Activeren
          </Button>
        );
      },
    },
  ];

  const sortableFields = [
    {
      label: "Naam",
      value: "firstName",
      defaultAscending: true,
    },
    {
      label: "E-mailadres",
      value: "email",
    },
    {
      label: "Werksector",
      value: "CurrentIndustry",
    },
  ];

  const [includeArchive, setIncludeArchive] = useState<boolean>();

  const onCheckIncludeArchive = async (active: boolean) => {
    setIncludeArchive(active);
  };

  const onClickExport = async () => {
    const data = await ExportService.usersExcel();

    exportUtil.exportCsv(data, "userslist");
  };

  const customFilters = (
    <>
      <div className="row">
        <div className="col-6">
          <CheckInput
            id="includeArchive"
            label="Gearchiveerde gebruikers tonen"
            defaultValue={false}
            key="includeArchive"
            onChange={onCheckIncludeArchive}
          />
        </div>

        <div className="col-6">
          <Button
            onClick={() => {
              onClickExport();
            }}
          >
            Lijst met gebruikers exporteren
          </Button>
        </div>
      </div>

      <div className="row">
        <div className="col-6 d-flex flex-wrap justify-content-between">
          <p>{`${INDUSTRY_NL.CONSTRUCTION}: ${IndustryStore.Construction}`}</p>
          <p>{`${INDUSTRY_NL.EDUCATION}: ${IndustryStore.Education}`}</p>
          <p>{`${INDUSTRY_NL.FINANCIAL_SERVICES}: ${IndustryStore.FinancialServices}`}</p>
          <p>{`${INDUSTRY_NL.INSTALLATION_SECTOR}: ${IndustryStore.InstallationSector}`}</p>
          <p>{`${INDUSTRY_NL.OTHER}: ${IndustryStore.Other}`}</p>
        </div>
      </div>
    </>
  );

  return (
    <OverviewPage
      name="Gebruikers"
      singular="Gebruiker"
      path="gebruikers"
      onLoad={() => UserStore.load(includeArchive)}
      sort={true}
      search={true}
      filters={UserStore.filters}
      columns={columns}
      items={UserStore.users}
      sortableFields={sortableFields}
      customFilters={customFilters}
    />
  );
};

export default observer(UsersOverviewPage);
