import CrudService from "./CrudService";
import { UserSnapshot } from "stores/UserStore/UserModel";
import { MeSnapshot } from "stores/UserStore/MeModel";
import { TicketSnapshot } from "stores/UserStore/TicketModel";
import PaginatedResponse from "stores/Models/PaginatedResponse";
import { RequestFiltersSnapshot } from "stores/Models/RequestFilters";
import ApiService from "./ApiService";

interface UserModel extends UserSnapshot {
  tickets: TicketSnapshot[];
}

interface IndustriesSnapshot {
  industries: {
    Construction: number;
    Education: number;
    FinancialServices: number;
    InstallationSector: number;
    Other: number;
  };
}

class UserService extends CrudService<UserModel> {
  listWithChildren = async (
    filters: RequestFiltersSnapshot,
    includeArchive: boolean = false
  ): Promise<PaginatedResponse<UserModel>> => {
    const response = await this.list(filters, {
      includeArchive: includeArchive ? 1 : 0,
    });

    response.results = response.results.map((user) => {
      user.tickets = user.tickets?.map((ticket) => {
        return {
          ...ticket,
          performance: ticket.performanceId,
        };
      });
      return user;
    });

    return response;
  };

  singleWithIncludes = async (id: number): Promise<UserModel> => {
    const user = await this.single(id);

    if (user) {
      user.tickets = user.tickets?.map((item) => {
        return {
          ...item,
          performance: item.performanceId,
        };
      });
    }

    return user;
  };

  createWithIncludes = async (
    userData: Partial<UserSnapshot>
  ): Promise<UserModel> => {
    const user = await this.create(userData);

    user.tickets = user.tickets?.map((item) => {
      return {
        ...item,
        performance: item.performanceId,
      };
    });

    return user;
  };

  me = async (): Promise<MeSnapshot> => {
    const response = await ApiService.get(`${this.basename}/me`);

    return response.data;
  };

  activate = async (id: number): Promise<UserSnapshot> => {
    const response = await ApiService.put(`auth/${id}/activate`);

    return response.data;
  };

  getIndustries = async (): Promise<IndustriesSnapshot> => {
    const response = await ApiService.get(`${this.basename}/industries`);

    return response.data;
  };
}

export default new UserService("/users");
