import React, { useEffect, useState } from "react";
import { useStores } from "stores";
import { PerformanceForm } from "components/forms";
import { PerformanceSnapshot } from "stores/PerformanceStore/PerformanceModel";
import DetailPage from "pages/Base/DetailPage";
import { observer } from "mobx-react-lite";
import { PerformanceAttendanceOverview } from "components/Locations";
import { ConfirmModal } from "components";
import { useHistory } from "react-router";

const PerformanceDetailPage = ({ match }: { match: { params: any } }) => {
  const { PerformanceStore } = useStores();

  useEffect(() => {
    (async () => {
      await PerformanceStore.current.reload();
    })();

    return () => {};
  });

  const onEditSubmit = async (
    performanceData: Partial<PerformanceSnapshot>
  ) => {
    await PerformanceStore.current?.update(performanceData);
  };

  const history = useHistory();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const onRemoveSubmit = async () => {
    await PerformanceStore.delete();
    history.push("/voorstellingen");
  };

  return (
    <>
      <DetailPage
        current={PerformanceStore.current}
        store={PerformanceStore}
        title="Voorstelling"
        form={
          <PerformanceForm
            onSubmitCallback={onEditSubmit}
            onRemoveCallback={() => {
              setShowDeleteModal(true);
            }}
            performance={PerformanceStore.current}
          ></PerformanceForm>
        }
        formColSize={12}
        match={match}
      />

      <ConfirmModal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        onConfirm={onRemoveSubmit}
        modalText="Weet je zeker dat je deze voorstelling wilt annuleren?"
        modalTitle={"Voorstelling annuleren"}
      ></ConfirmModal>

      {PerformanceStore.current && <PerformanceAttendanceOverview />}
    </>
  );
};

export default observer(PerformanceDetailPage);
